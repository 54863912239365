<template>
  <header id="main-header">
    <transition
      appear
      @before-enter="beforeEnter"
      @enter="enter"
    >
    <h2>
      <router-link
        to="/"
        @mouseenter="triggerAnim(true)"
        @mouseleave="triggerAnim(false)"
      >
        <b>Promotica</b>&nbsp;
        <v-icon name="la-shipping-fast-solid" />
        &nbsp;Orders Manager
      </router-link>
    </h2>
    </transition>

    <button v-if="$store.state.user" class="logout-btn" @click="logout">
      <v-icon name="ri-logout-box-line" scale="1.25" />
    </button>
  </header>
  <transition name="alert">
    <aside
      class="main-alert"
      :class="{error : alertError}"
      v-if="alertMsg"
    >
      {{ alertMsg }}
    </aside>
  </transition>

  <router-view v-slot="{ Component }" v-if="!loading">
    <transition name="page">
      <component :is="Component" />
    </transition>
  </router-view>

  <div class="min-h-screen w-full inline-block" v-else />

  <footer id="main-footer">
    <small class="credits">
      Copyright © 2022 - Promotica S.p.A. - P.IVA e C.F.: 02394460980
      Sede in via Generale dalla Chiesa, 1 Desenzano del Garda (BS)
      Reg. Imp. Brescia n. 02394460980 - Capitale sociale: 800.000 €
      PEC: promotica@pec.promotica.it
    </small>
  </footer>
</template>

<script>
import { mapGetters, useStore } from 'vuex'
import gsap from 'gsap'

export default {
  setup () {
    const store = useStore()
    store.commit('initializeStore')
    if (store.state.user) {
      store.dispatch('getUser')
      store.dispatch('getAgreements')
    }
    const beforeEnter = (el) => {
      gsap.set(el, {
        opacity: 0,
        x: '-=10'
      })
    }
    const enter = (el) => {
      gsap.to(el, {
        delay: 0.4,
        duration: 1,
        opacity: 1,
        x: '0'
      })
    }
    return {
      beforeEnter,
      enter
    }
  },
  data () {
    return {
      anim: null,
      alertMsg: null,
      alertError: null,
      loading: true
    }
  },
  computed: {
    ...mapGetters([
      'getLocalVersion',
      'getDesiredVersion'
    ])
  },
  methods: {
    triggerAnim (boolean) {
      if (boolean) this.anim = 'flash'
      else this.anim = null
    },
    throwError () {
      throw new Error('Sentry Error')
    },
    async logout () {
      await this.$store.dispatch('logout')
        .then(() => this.$router.push('/login'))
    },
    async checkDesiredVersion () {
      await this.$store.dispatch('getWebsiteVersion')
        .then((rsp) => {
          if (rsp && rsp > this.getLocalVersion) {
            console.warn('VERSION is going to be updated', this.getLocalVersion + ' ---> ' + rsp)
            setTimeout(() => {
              console.warn('VERSION is going to be updated', this.getLocalVersion + ' ---> ' + rsp)
              this.$router.go()
            }, 4000)
          }
          this.$store.commit('updateDesiredVersion', rsp)
        })
        .catch((err) => {
          console.error('GET WEBSITE VER', err)
        })
    }
  },
  async mounted () {
    // console.log('---STATE', this.$store.state)
    this.emitter.on('fireAlert', (data) => {
      this.alertMsg = data.msg
      this.alertError = data.error
      setTimeout(() => {
        this.alertMsg = null
        this.alertError = null
      }, 6000)
    })
    this.$store.commit('updateLocalVersion', process.env.VUE_APP_VER ? process.env.VUE_APP_VER : 1)
    await this.checkDesiredVersion()
    setInterval(() => {
      this.checkDesiredVersion()
    }, 300000) // ---5 MINUTES
    this.loading = false
  },
  meta: {
    title: 'Promotica | Orders Manager',
    metaTags: [
      {
        name: 'description',
        content: 'Orders Management System by Promotica S.p.A.'
      },
      {
        property: 'og:description',
        content: 'Orders Management System by Promotica S.p.A.'
      }
    ]
  }
}
</script>
<style lang="scss">
  @import url('https://use.typekit.net/rjh2mos.css');
  @import "@/assets/scss/_mixins.scss";
  @import "@/assets/scss/app.scss";
</style>

<template>
<div class="page-view not-found">
  <div class="container text-center">
    <h1>404 - Page not found</h1>

    <figure>
      <img class="bg-image" src="@/assets/img/error.svg" />
    </figure>

    <router-link class="back-to" to="/">
      ‹ Torna alla <b>Home</b>
    </router-link>

  </div>
</div>
</template>

<script>

export default {}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/views/404.scss";
</style>

// ---IMPORT JS
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Particles from 'particles.vue3'
import mitt from 'mitt'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { OhVueIcon, addIcons } from 'oh-vue-icons'
import vClickOutside from 'click-outside-vue3'
import {
  LaShippingFastSolid,
  FaChevronRight,
  FaChevronLeft,
  FaSpinner,
  BiArrowRightShort,
  BiArrowLeftShort,
  PrPlusCircle,
  PrMinusCircle,
  RiLogoutBoxLine
} from 'oh-vue-icons/icons'

import './utils/vee-validate'
// ---IMPORT CSS
import './index.css'
// import './assets/css/tailwind.css'

let origin = window.location.origin
console.log(origin)
if (origin === 'http://localhost:3000') origin = 'http://localhost'

// ---CONFIG
// * https://www.smashingmagazine.com/2020/10/authentication-in-vue-js/
axios.defaults.withCredentials = true
axios.defaults.baseURL = origin + '/api/v1' // process.env.VUE_APP_BASE_URL

// * https://oh-vue-icons.js.org/
addIcons(
  LaShippingFastSolid,
  FaChevronLeft,
  FaChevronRight,
  FaSpinner,
  BiArrowRightShort,
  BiArrowLeftShort,
  PrPlusCircle,
  PrMinusCircle,
  RiLogoutBoxLine
)

const app = createApp(App)
// --Global Vars
// app.config.globalProperties.projectName = 'Promotica Orders Manager'

// https://docs.sentry.io/platforms/javascript/guides/vue/#vue-3
Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'localhost:3000', 'https://ordini-unicomm.promotica.it', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

app
  .use(router)
  .use(store)
  .use(VueAxios, axios)
  .use(Particles)
  .use(vClickOutside)
  .component('v-icon', OhVueIcon)

const emitter = mitt()
app.config.globalProperties.emitter = emitter

app
  .mount('#app') // ---FIRED UP, AND READY TO SERVE 🤖

// ---DEBUG .env
// console.log(process.env.VUE_APP_NAME + ' - ' + process.env.NODE_ENV)

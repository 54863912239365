import { createRouter, createWebHistory } from 'vue-router'
import * as Sentry from '@sentry/vue'
import store from '@/store'
import NotFound from '@/views/404.vue'

const routes = [
  {
    path: '/',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/login',
    component: () => import('@/views/Login.vue')
  },
  {
    name: 'Campaign',
    path: '/campaign/:id',
    props: true,
    component: () => import('@/views/Campaign.vue')
  },
  // {
  //   path: '/confirm',
  //   component: () => import('@/views/Confirm.vue')
  // },
  // {
  //   path: '/reset',
  //   component: () => import('@/views/Reset.vue')
  // },
  // {
  //   path: '/recovery',
  //   component: () => import('@/views/Recovery.vue')
  // },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  Sentry.configureScope((scope) => scope.setTransactionName(to?.meta?.label))
  await store.commit('initializeStore')
  const loggedIn = store.state.user
  if (loggedIn) {
    await store.dispatch('getUser')
    await store.dispatch('getAgreements')
  }
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    '/login'
  ]
  const authRequired = !publicPages.includes(to.path)

  // console.log('I AM THE ROUTER GUARD', authRequired, loggedIn)
  if (authRequired && !loggedIn) {
    return next('/login')
  }

  next()
})
export default router
